<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="actions flex flex-wrap gap-4 items-center my-5">
        <div class="flex justify-between full-width">
          <h1>{{ $t("start_times") }}</h1>
          <t-button
            type="button"
            variant="primary"
            class="text-sm"
            @click="openDialog"
          >
            {{ $t("add_start_time") }}
          </t-button>
        </div>
        <t-table class="w-full mt-5" :headers="locations" :data="startTimes">
          <template slot="thead" slot-scope="props">
            <thead>
              <tr>
                <th
                  v-for="location in props.data"
                  :key="location.id"
                  class="bg-gray-200 p-3"
                >
                  {{ location.description }}
                </th>
              </tr>
            </thead>
          </template>
          <template slot="tbody" slot-scope="props">
            <tr>
              <td
                v-for="cols in props.headers"
                :key="cols.id"
                class="p-0 align-top"
              >
                <div
                  v-for="time in props.data"
                  :key="time.id"
                  class="border-1 flex justify-center align-center"
                  :class="
                    time && time.horeca_location_id == cols.id
                      ? 'custom-table'
                      : 'border-0'
                  "
                >
                  <p v-if="time.horeca_location_id == cols.id" class="py-2">
                    {{ time.start_time }}
                  </p>
                  <t-button
                    variant="danger"
                    class="flex-item3 text-xs mt-2"
                    v-if="time.horeca_location_id == cols.id"
                    @click="deleteStartTime(time)"
                  >
                    <i class="fas fa-times"></i>
                  </t-button>
                </div>
              </td>
            </tr>
          </template>
        </t-table>
      </div>
      <t-modal v-model="dialog" hideCloseButton @closed="closeDialog">
        <template slot="header"> Add start time </template>

        <template slot="default">
          <t-table class="border-0">
            <template slot="tbody">
              <tr class="border-0">
                <td class="text-right pr-3 border-0">Horeca Location:</td>
                <td class="border-0">
                  <t-select
                    class="full-width"
                    v-model="newStartTime.horeca_location_id"
                    :options="locations"
                    valueAttribute="id"
                    textAttribute="description"
                  ></t-select>
                </td>
              </tr>
              <tr class="border-0">
                <td class="text-right pr-3 border-0">Start Time</td>
                <td class="border-0">
                  <t-datepicker
                    v-model="newStartTime.start_time"
                    user-format="H:i"
                    date-format="H:i"
                    timepicker
                    :datepicker="false"
                  />
                </td>
              </tr>
            </template>
          </t-table>
        </template>
        <template slot="footer">
          <div class="flex justify-end">
            <t-button
              variant="secondary"
              class="text-sm mx-3"
              @click="closeDialog"
            >
              {{ $t("cancel") }}
            </t-button>
            <t-button variant="primary" class="text-sm" @click="addStartTime">
              {{ $t("save") }}
            </t-button>
          </div>
        </template>
      </t-modal>
      <t-dialog icon="question" type="confirm"></t-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { mapActions, mapGetters } from "vuex";
import { actions, getters } from "@/constants/state";
import * as _ from "lodash";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    selectedHorecaLocation: null,
    dialog: false,
    newStartTime: {
      horeca_location_id: 1,
    },
  }),

  computed: {
    ...mapGetters({
      locations: getters.HORECA_LOCATIONS_GETTER,
      horecaLocationId: getters.DOMAIN_LOCATION_ID_GETTER,
      startTimes: getters.START_TIMES_GETTER,
    }),
  },

  async mounted() {
    await this.setStartTimes();
  },

  methods: {
    ...mapActions({
      setStartTimes: actions.SET_START_TIMES_ACTION,
      addStartTimes: actions.ADD_START_TIMES_ACTION,
      destroyStartTime: actions.DELETE_START_TIMES_ACTION,
    }),

    async addStartTime() {
      try {
        if (!this.newStartTime.horeca_location_id) {
          this.$dialog.alert(
            "Error",
            "You didn't provide a location.",
            "warning"
          );
          return;
        }
        if (!this.newStartTime.start_time) {
          this.$dialog.alert("Error", "You didn't provide a time.", "warning");
          return;
        }

        await this.addStartTimes(this.newStartTime);
        this.closeDialog();
      } catch (error) {
        this.closeDialog();
        this.$dialog.alert("Something went wrong!", error.data, "warning");
      }
    },

    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.newStartTime = { horeca_location_id: 1 };
    },

    deleteStartTime(time) {
      try {
        const locationName = _.find(this.locations, {
          id: time.horeca_location_id,
        });
        this.$dialog
          .confirm(
            "Delete start time?",
            `You are deleting start time ${time.start_time} of ${
              locationName
                ? locationName.description
                : `location ${time.horeca_location_id}`
            }. This action cannot be undone.`,
            "warning"
          )
          .then(async (result) => {
            if (result.isOk) {
              await this.destroyStartTime(time.id);
            }
          });
      } catch (error) {
        this.$dialog.alert("Something went wrong!", error.data, "warning");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 20px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.full-width {
  width: 100%;
}

.border-0 {
  border: 0 !important;
}

.custom-table {
  border-bottom: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
  border-left: 1px solid #e5e7eb;
}

.flex-item3 {
  width: 10px;
  height: 25px;
  text-align: center;
  padding-top: 4px;
  padding-left: 8px;
  margin-left: 5px;
  border-radius: 50%;
}
</style>
